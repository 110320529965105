import { useEffect, useState } from "react";

import triangle from "../../assets/icons/triangle.png"

import "./FAQ.scss"

function FAQ ({set}){

    const [answerOpen, setAnswerOpen] = useState(false)


    const toggleAnswer = (click) =>{
        click.preventDefault();
        setAnswerOpen(!answerOpen)
    }

    useEffect(()=>{
        if(answerOpen === false){
            setTriangleDown(true)
            setAnswerHide(true)
        }else{
            setTriangleDown(false)
            setAnswerHide(false)
        }
    },[answerOpen])

    //flipping the triangle when faq question is clicked
    const [triangleDown, setTriangleDown] = useState(true)

    //hiding answers until the question is opened
    const [answerHide, setAnswerHide] = useState(true)


    return(
        <div className="faq__box">
            <div onClick={toggleAnswer} className="faq__question-container">
                <p className="faq__question">{set.question}</p>
                <div className="faq__triangle-container">
                    <img src={triangle} alt="triangle" className={triangleDown ? "faq__triangle__down" : "faq__triangle__up"}/>
                </div>
            </div>
            <div className={answerHide ? "faq__answer-container__hidden" : "faq__answer-container"}>
                <p className="faq__answer">{set.answer}</p>
            </div>  
        </div>
    )


}

export default FAQ;
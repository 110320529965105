// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './Carousel.scss';

// import required modules
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';
import courses from '../../assets/courses';
import { Link } from 'react-router-dom';

function Carousel() {


  //find current courses
  const currentCourses = courses.filter(array=>array.pastcourse === false)

  
  return (
    <article className='carousel'>
      <Swiper
        effect={'flip'}
        grabCursor={true}
        pagination={true}
        navigation={true}
        modules={[EffectFlip, Pagination, Navigation]}
        className="mySwiper"
      >
        {currentCourses.map(course=>{
            return(
                <SwiperSlide key={course.landing.date} className='carousel__slide'>
                    <div className='carousel__photo-container'>
                        <img src={course.landing.landingphoto} alt={course.landing.instructor} className='carousel__photo'/>
                    </div>
                    <div className='carousel__text-container'>
                        <h1 className='carousel__title'>{course.landing.title}</h1>
                        <h2 className='carousel__date'>{course.landing.date}</h2>
                        <h3 className='carousel__instructor'>{course.landing.instructor}</h3>
                    </div>
                    <div className='carousel__learn-container'>
                        <Link to={`/courses/${course.name}`} className='carousel__learn'>Learn more</Link>
                    </div>

                </SwiperSlide>
            )
        })}
      </Swiper>
    </article>
  );
}


export default Carousel;
import courses from "./courses";
//only change this file when wording need to change.
//all dynamic values are done in courses.js

//automatically change the application name from the courses.js value.
//keep this logic
const applicationname = [];

courses.map((course) => {
  const string = course.landing.title;
  const city = string.substring(0, string.indexOf(","));
  const date = course.landing.date;
  const appname = city + " " + date;
  applicationname.push(appname);
});

const application = {
  introduction:
    "We look forward to getting to know you better. If you have any questions regarding the process, please contact us using our contact form or using contact@dirigierakademie.com. The application fee for each course will be 25 eur/cad/gbp depending on the location of the course. Priority will be given to early applicants. Before apply, please read through the following:",
  procedure: [
    "Submit the application form of the desired course.",
    "Once the form is submitted, you will receive an email with a secure link for application fee payment. This fee is non-refundable.",
    "Attach the application fee payment receipt along with your CV/resumé to contact@dirigierakademie.com. This will complete your application process.",
    "For accepted applicants, we will request a 50% deposit, payable within 14 days of acceptance. Full payment must be received 14 days before the start of the course you are attending.",
    "In case of cancellation by the Dirigierakademie, deposit and course fees will be refunded.",
    "In case of cancellation by the applicant, course fees will not be refunded unless a replacement participant is found.",
    "The course information on this website is accurate at time of writing. We reserve the right to alter aspects of the course if necessary. For example, the day-to-day schedule can only be finalized after the application closes.",
  ],
  //this is automatically changed when courses.js change its values.
  //don't need to change
  courses: [`${applicationname[0]}`, `${applicationname[1]}`],

  //list items under summary of conducting experience
  conductingexp: [
    "music education",
    "recent performances",
    "previous masterclasses",
    "etc.",
  ],
  applicationSpecific: [
    // dresen section
    {
      coursename: `${applicationname[0]}`,
      // repmsg:
      //   "Applicants may be assigned any 3 out of the 6 pieces below. Your preferences will be considered and accommodated to the best of our abilities, but we cannot guarantee all your selections. ",
      // repertoirepref: [
      //   {
      //     date: `${courses[0].schedule[1].date}`,
      //     pieces: [
      //       `${courses[0].schedule[1].time[0].objective}`,
      //       `${courses[0].schedule[1].time[2].objective}`,
      //       `No preference`,
      //     ],
      //   },
      //   {
      //     date: `${courses[0].schedule[2].date}`,
      //     pieces: [
      //       `${courses[0].schedule[2].time[0].objective}`,
      //       `${courses[0].schedule[2].time[2].objective}`,
      //       `No preference`,
      //     ],
      //   },
      //   {
      //     date: `${courses[0].schedule[3].date}`,
      //     pieces: [
      //       `${courses[0].schedule[3].time[0].objective}`,
      //       `${courses[0].schedule[3].time[2].objective}`,
      //       `No preference`,
      //     ],
      //   },
      // ],
    },
    //toronto application section

    // {
    //   coursename: `${applicationname[1]}`,
    // },
  ],
};
export default application;

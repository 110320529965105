import { NavLink, Link } from "react-router-dom";
import { useEffect} from "react";

import triangle from "../../assets/icons/triangle.png"
import courses from "../../assets/courses"

import "./HamburgerMenu.scss"


function HamburgerMenu({toggleHamburger, setCoursesHighlighted, coursesHighlighted, triangleClick, triangleClicked}){

    //find current courses
    const currentCourses = courses.filter(array=>array.pastcourse === false)


    const currentURL = window.location.pathname

    useEffect(()=>{
        if (currentURL.includes("courses")){
            setCoursesHighlighted(true)
        }else{
            setCoursesHighlighted(false)
        }
    // eslint-disable-next-line
    },[currentURL])

    return(

        <section className="hamburgermenu">
            <div className="hamburgermenu__header">
                <div className="hamburgermenu__border" onClick={toggleHamburger}>
                    <span aria-hidden="true" className="hamburgermenu__close">&times;</span>
                </div>
            </div>

            <ul className="hamburgermenu__list">
                <li className="hamburgermenu__item" onClick={toggleHamburger}>
                    <NavLink to="/" className={({ isActive, isPending }) =>
    isPending ? "hamburgermenu__pending" : isActive ? "hamburgermenu__active hamburgermenu__navlink" : "hamburgermenu__navlink"
  }>Home</NavLink>
                </li>
                <li className="hamburgermenu__item" onClick={toggleHamburger}>
                    <NavLink to="/about" className={({ isActive, isPending }) =>
    isPending ? "hamburgermenu__pending" : isActive ? "hamburgermenu__active hamburgermenu__navlink" : "hamburgermenu__navlink"
  }>About</NavLink>
                </li>
                <li className="hamburgermenu__item hamburgermenu__course" onClick={triangleClick}>
                    <p className={coursesHighlighted ? "hamburgermenu__active hamburgermenu__navlink" : "hamburgermenu__navlink"}>Courses</p>
                    <img src={triangle} alt="triangle" className={triangleClicked ? "hamburgermenu__triangle__up" : "hamburgermenu__triangle__down"}/>
                    <div className={triangleClicked ? "hamburgermenu__dropdown-container" : "hamburgermenu__dropdown-container__hidden"}>
                        <ul className="hamburgermenu__dropdown-list">
                            {currentCourses.map(course=>{
                                return(
                                    <li className="hamburgermenu__dropdown-item" key={course.name}>
                                        <Link to={`/courses/${course.name}`} onClick={toggleHamburger} className="hamburgermenu__dropdown-item">{course.name}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </li>
                <li className="hamburgermenu__item" onClick={toggleHamburger}>
                    <NavLink to="/instructors" className={({ isActive, isPending }) =>
    isPending ? "hamburgermenu__pending" : isActive ? "hamburgermenu__active hamburgermenu__navlink" : "hamburgermenu__navlink"
  }>Instructors</NavLink>
                </li>
                <li className="hamburgermenu__item" onClick={toggleHamburger}>
                    <NavLink to="/contact" className={({ isActive, isPending }) =>
    isPending ? "hamburgermenu__pending" : isActive ? "hamburgermenu__active hamburgermenu__navlink" : "hamburgermenu__navlink"
  }>Contact</NavLink>
                </li>
                <li className="hamburgermenu__item" onClick={toggleHamburger}>
                    <NavLink to="/apply" className={({ isActive, isPending }) =>
    isPending ? "hamburgermenu__pending" : isActive ? "hamburgermenu__active hamburgermenu__navlink" : "hamburgermenu__navlink"
  }>Apply</NavLink>
                </li>
            </ul>
        </section>
    )
}

export default HamburgerMenu;
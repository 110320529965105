import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";


import "./ApplicationForm.scss"

function ApplicationForm ({applyInfo, applyGeneral, setModalMessage, setModalOpen, modalOpen}){

    

    const { register, handleSubmit, reset} = useForm();

    const onSubmit = ((event) =>{

        if (applyInfo.coursename.includes("London")){
            event.formId = "3c194c47-33ee-4ca1-b1cc-85e7d2146b54"
        }else if (applyInfo.coursename.includes("Toronto")){
            event.formId = "5f46c61b-0de3-4cdc-973b-9809205fa51a"
        }else {
            console.log("unable to find form id")
        }
        
        const reqOptions = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(event)
        };
        fetch('https://yu-form.kyles.wtf/submit-form', reqOptions)
        .then((response)=>{
            console.log(response.ok)
            reset();
            setModalOpen(true)
            setModalMessage([
                `Thank you for your application!`, 
                `We've received your application and will reach out to you at ${event.applyEmail}.`,
                `Feel free to reach us at contact@dirigierakademie.com.`,
            `If you have not received a confirmation email from us soon after, please check your spam folder.`])
        })
        .catch(error => console.error(error));
    })



    //form resets if the applicant clicks a different course to apply
    useEffect(()=>{
        reset();
    },[applyInfo])

    if (applyInfo.coursename.includes("Dresden")){
        
        return(
            
            <form onSubmit={handleSubmit(onSubmit)} className={modalOpen ? "applyform__modal" : "applyform"}>
            {/* this input is will show up in the email, showing which course the application is for. its css will be hidden */}
                <input readOnly name='applyCourse' value={applyInfo.coursename} className="applyform__hidden"/>
                <div className="applyform__short-container">
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyFirstName"> First Name *</label>
                        <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyFirstName", {required: true})} />
                    </div>
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyLastName"> Last Name *</label>
                        <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLastName", {required: true})} />
                    </div>
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyEmail"> Email *</label>
                        <input type="email" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyEmail", {required: true})} />
                    </div>
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyNationality"> Nationality *</label>
                        <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyNationality", {required: true})} />
                    </div>
                </div>
                <div className="applyform__field-container applyform__message-container">
                    <label className="applyform__label" htmlFor="applySummary">Brief summary of conducting experience *</label>
                    <ul className="applyform__summary-list">
                        {applyGeneral.map((item)=>{
                            return(
                                <li className="applyform__summary-item" key={uuidv4()}>{item}</li>
                            )
                        })}
                    </ul>
                    <textarea className={modalOpen ? "applyform__field applyform__field__modal applyform__message" : "applyform__field applyform__message"}{...register("applySummary", {required: true})}/>
                </div>

            <div className="applyform__field-container">
                <label className="applyform__label" htmlFor="applyLink1"> Link to conducting video 1 *</label>
                <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLink1", {required: true})} />
            </div>

            <div className="applyform__field-container">
                <label className="applyform__label" htmlFor="applyLink2"> Link to conducting video 2 *</label>
                <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLink2", {required: true})} />
            </div>

            <div className="applyform__field-container">
                <label className="applyform__label" htmlFor="applyLink3"> Link to conducting video 3</label>
                <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLink3", {})} />
            </div>

            <div className="applyform__field-container ">
                <p className="applyform__label">Repertoire preferences</p>
                <p className="applyform__details">{applyInfo.repmsg}</p>
                <div className="applyform__repertoire-container">
                    <div className="applyform__choice-container">
                        <p className="applyform__choice-day">{applyInfo.repertoirepref[0].date}</p>
                        {applyInfo.repertoirepref[0].pieces.map((piece)=>{
                            return(
                                <div className="applyform__piece-container" key={piece}>
                                    <input {...register("choice1", { required: true })} type="radio" value={piece} />
                                    <label htmlFor="choice1" className="applyform__piece">{piece}</label>
                                </div>
                            )
                        })}
                    </div>
                    <div className="applyform__choice-container">
                        <p className="applyform__choice-day">{applyInfo.repertoirepref[1].date}</p>
                        {applyInfo.repertoirepref[1].pieces.map((piece)=>{
                            return(
                                <div className="applyform__piece-container" key={piece}>
                                    <input {...register("choice2", { required: true })} type="radio" value={piece} />
                                    <label htmlFor="choice2" className="applyform__piece">{piece}</label>
                                </div>
                            )
                        })}
                    </div>
                    <div className="applyform__choice-container">
                        <p className="applyform__choice-day">{applyInfo.repertoirepref[2].date}</p>
                        {applyInfo.repertoirepref[2].pieces.map((piece)=>{
                            return(
                                <div className="applyform__piece-container" key={piece}>
                                    <input {...register("choice3", { required: true })} type="radio" value={piece} />
                                    <label htmlFor="choice3" className="applyform__piece">{piece}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="applyform__button-container">
                <button type="submit" className={modalOpen ? "applyform__button applyform__button__modal" : "applyform__button"}>Confirm and submit</button>      
            </div>
        </form>
    )

    }else{

        return(
            <form onSubmit={handleSubmit(onSubmit)} className={modalOpen ? "applyform__modal" : "applyform"}>
            {/* this input is will show up in the email, showing which course the application is for. its css will be hidden */}
                <input readOnly name='applyCourse' value={applyInfo.coursename} className="applyform__hidden"/>
                <div className="applyform__short-container">
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyFirstName"> First Name *</label>
                        <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyFirstName", {required: true})} />
                    </div>
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyLastName"> Last Name *</label>
                        <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLastName", {required: true})} />
                    </div>
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyEmail"> Email *</label>
                        <input type="email" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyEmail", {required: true})} />
                    </div>
                    <div className="applyform__field-container applyform__field-short">
                        <label className="applyform__label" htmlFor="applyNationality"> Nationality *</label>
                        <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyNationality", {required: true})} />
                    </div>
                </div>
                <div className="applyform__field-container applyform__message-container">
                    <label className="applyform__label" htmlFor="applySummary">Brief summary of conducting experience *</label>
                    <ul className="applyform__summary-list">
                        {applyGeneral.map((item)=>{
                            return(
                                <li className="applyform__summary-item" key={uuidv4()}>{item}</li>
                            )
                        })}
                    </ul>
                    <textarea className={modalOpen ? "applyform__field applyform__field__modal applyform__message" : "applyform__field applyform__message"}{...register("applySummary", {required: true})}/>
                </div>

            
            {/* <div className="applyform__field-container applyform__video-options">
                <p className="applyform__label">Application video options:</p>
                <ul className="applyform__summary-list">
                    <li className="applyform__summary-item">A video of the applicant conducting an orchestra, band, or other ensemble.</li>
                    <li className="applyform__summary-item">A video of the applicant conducting symphonic repertoire with two pianists.</li>
                    <li className="applyform__summary-item">A video of the applicant air conducting one of the pieces on the Dirigierakademie Toronto repertoire list.</li>
                </ul>

            </div> */}

            <div className="applyform__field-container">
                <label className="applyform__label" htmlFor="applyLink1"> Link to conducting video 1 *</label>
                <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLink1", {required: true})} />
            </div>

            <div className="applyform__field-container">
                <label className="applyform__label" htmlFor="applyLink2"> Link to conducting video 2 *</label>
                <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLink2", {required: true})} />
            </div>

            <div className="applyform__field-container">
                <label className="applyform__label" htmlFor="applyLink3"> Link to conducting video 3 (optional)</label>
                <input type="text" className={modalOpen ? "applyform__field applyform__field__modal" : "applyform__field"} {...register("applyLink3", {})} />
            </div>
            <div className="applyform__field-container">
                <p className="applyform__label">How did you hear about us *</p>
                {/* <p className="applyform__choice-day">{applyInfo.repertoirepref[0].date}</p>
                        {applyInfo.repertoirepref[0].pieces.map((piece)=>{
                            return(
                                <div className="applyform__piece-container" key={piece}>
                                    <input {...register("choice1", { required: true })} type="radio" value={piece} />
                                    <label htmlFor="choice1" className="applyform__piece">{piece}</label>
                                </div>
                            )
                        })} */}
                <div className="applyform__survey-container">
                    <input {...register("applySurvey", { required: true })} type="radio" value="friend" />
                    <label htmlFor="applySurvey" className="applyform__survey">Friend</label>
                </div>
                <div className="applyform__survey-container">
                    <input {...register("applySurvey", { required: true })} type="radio" value="" />
                    <label htmlFor="applySurvey" className="applyform__survey">School</label>
                </div>
                <div className="applyform__survey-container">
                    <input {...register("applySurvey", { required: true })} type="radio" value="musical chairs" />
                    <label htmlFor="applySurvey" className="applyform__survey">Musical Chairs</label>
                </div>
                <div className="applyform__survey-container">
                    <input {...register("applySurvey", { required: true })} type="radio" value="social media" />
                    <label htmlFor="applySurvey" className="applyform__survey">Social media</label>
                </div>
                <div className="applyform__survey-container">
                    <input {...register("applySurvey", { required: true })} type="radio" value="other" />
                    <label htmlFor="applySurvey" className="applyform__survey">Other</label>
                </div>
            </div>
            <div className="applyform__button-container">
                <button type="submit" className={modalOpen ? "applyform__button applyform__button__modal" : "applyform__button"}>Confirm and submit</button>    
            </div>
        </form>
    )
    }



}

export default ApplicationForm;
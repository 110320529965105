import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import LandingPage from "./pages/LandingPage/LandingPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import CoursesPage from "./pages/CoursesPage/CoursesPage";
import InstructorsPage from "./pages/InstructorsPage/InstructorsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import ApplyPage from "./pages/ApplyPage/ApplyPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";

import "./App.scss";

function App() {
  //activate modal for successful form submission
  const [modalOpen, setModalOpen] = useState(false);

  //response message
  const [modalMessage, setModalMessage] = useState([]);

  return (
    <>
      <BrowserRouter>
        <div className="keep__footer__down">
          <Modal
            modalMessage={modalMessage}
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
          />
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/courses/:courseName" element={<CoursesPage />} />
            <Route path="/instructors" element={<InstructorsPage />} />
            <Route
              path="/contact"
              element={
                <ContactPage
                  setModalMessage={setModalMessage}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                />
              }
            />
            <Route
              path="/apply"
              element={
                <ApplyPage
                  setModalMessage={setModalMessage}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                />
              }
            />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;

import { useEffect, useState } from "react";

import application from "../../assets/application";
import courses from "../../assets/courses";

import ApplicationForm from "../../components/ApplicationForm/ApplicationForm";

import "./ApplyPage.scss"
function ApplyPage ({setModalMessage, setModalOpen, modalOpen}){



    //pass application specific information to the applicationform container
    const [applyInfo, setApplyInfo] = useState()

    const onClickCourseEurope = (e) =>{

        if(applyEUOpen === true){
            setDisplayForm(true)
            setApplyInfo(application.applicationSpecific[0])
            setHightlightEU(true)
            setHightlightNA(false)
        }else{
            setDisplayForm(false)
            setHightlightEU(false)
            setHightlightNA(false)
        }

    }
    const onClickCourseNA = (e) =>{

        if(applyNAOpen === true){
            setDisplayForm(true)
            setApplyInfo(application.applicationSpecific[1])
            setHightlightEU(false)
            setHightlightNA(true)
        }else{
            setDisplayForm(false)
            setHightlightEU(false)
            setHightlightNA(false)
        }

    }


    //hide applicationform container until one of the buttons are clicked
    const [displayForm, setDisplayForm] = useState(false)

    //highlight selected masterclass 
    const [hightlightEU, setHightlightEU] = useState(false)
    const [hightlightNA, setHightlightNA] = useState(false)


    //gray out application button when course isn't open for application
    const [applyEUOpen, setApplyEUOpen] = useState()
    const [applyNAOpen, setApplyNAOpen] = useState()

    useEffect(()=>{
        if(courses[0].applicationstarted === true){
            setApplyEUOpen(true)
        }else{
           setApplyEUOpen(false) 
        }

        if(courses[1].applicationstarted === true){
            setApplyNAOpen(true)
        }else{
            setApplyNAOpen(false)
        }
    },[applyInfo])


    if(applyInfo){
        return(
            <section className='applypage'>
                <div className="applypage__title-container">
                    <h1 className="applypage__title">Application</h1>
                </div>
                <div className='applypage__intro-container'>
                    <p className='applypage__intro'>{application.introduction}</p>
                </div>
                <div className="applypage__procedure-container">
                    <ul className="applypage__list">
                    {application.procedure.map((list)=>{
                        return(
                            <li className="applypage__list-item" key={list}>{list}</li>
                        )
                    })}
                    </ul>
                </div>
                <div className='applypage__button-container'>
                    <p className="applypage__applytext">Apply for: </p>
                    <button type='button' className={`applypage__button ${applyEUOpen ? "applypage__button" : "applypage__button__gray"} ${hightlightEU ? "applypage__button__highlight" : "applypage__button"}`} onClick={onClickCourseEurope}>{application.courses[0]}</button>
                    <button type="button" className={`applypage__button ${applyNAOpen ? "applypage__button" : "applypage__button__gray"} ${hightlightNA ? "applypage__button__highlight" : "applypage__button"}`} onClick={onClickCourseNA}>{application.courses[1]}</button>
                </div>
                <div className= {displayForm ? "applypage__form" : "applypage__form__hidden"}>
                    <ApplicationForm applyGeneral={application.conductingexp} applyInfo={applyInfo} setModalMessage={setModalMessage}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}/>
                </div>
    
            </section>
            
        )
    }else{
        return(
            <section className='applypage'>
            <div className="applypage__title-container">
                <h1 className="applypage__title">Application</h1>
            </div>
            <div className='applypage__intro-container'>
                <p className='applypage__intro'>{application.introduction}</p>
            </div>
            <div className="applypage__procedure-container">
                    <ul className="applypage__list">
                    {application.procedure.map((list)=>{
                        return(
                            <li className="applypage__list-item" key={list}>{list}</li>
                        )
                    })}
                    </ul>
                </div>
            <div className='applypage__button-container'>
                <p className="applypage__applytext">Apply for: </p>
                <button type='button' className={applyEUOpen ? 'applypage__button' : "applypage__button__gray applypage__button "} onClick={onClickCourseEurope}>{application.courses[0]}</button>
                <button type="button" className={applyNAOpen ? 'applypage__button' : "applypage__button__gray applypage__button "} onClick={onClickCourseNA}>{application.courses[1]}</button>
            </div>
        </section>
        )
    }

}

export default ApplyPage;
import "./Modal.scss"

function Modal({modalMessage, setModalOpen, modalOpen}){


    return(
        <article className={modalOpen ? "modal" : "modal__hidden"}>
            <section className="modal__header">
                <div className="modal__border" onClick={()=>setModalOpen(false)}>
                    <span aria-hidden="true" className="modal__close">&times;</span>
                </div>
            </section>
            <section className="modal__message">
                {modalMessage.map((message)=>{
                    return(
                        <p className="modal__text" key={message}>{message}</p>
                    )
            })}
            </section>
            <section className="modal__button-container">
                    <button type="button" className="modal__button" onClick={()=>setModalOpen(false)}>CLOSE</button>
            </section>

    </article>
    )
}    




export default Modal;
import { useNavigate } from "react-router-dom";

import courses from "../../assets/courses";

import "./AboutPage.scss";

function AboutPage (){

    const navigate = useNavigate();

    const pastCourses = courses.filter(array =>array.pastcourse === true)

    return(
        <section className="about">
            <div className="about__title-container">
                <h1 className="about__title">About us</h1>
                <p className="about__description">
                Our organization is dedicated to offering top-tier orchestral conducting masterclasses, designed to refine the craft and technique of conducting. Led by two highly experienced conductors, our courses provide an in-depth exploration of the art of conducting, ensuring that each participant receives a well-rounded education. </p>
                <p className="about__description">With courses available in the UK, Europe, and North America, we are committed to reaching a diverse range of aspiring and established conductors. Our mission is to support musicians and conductors at every stage of their development, providing them with the tools, knowledge, and encouragement needed to succeed in their careers. </p>
                <p className="about__description">We pride ourselves on fostering a supportive and fair atmosphere, where every participant is given the same opportunity to learn, practice, and excel. Our approach ensures that each conductor, regardless of their experience level, receives personalized feedback and guidance, empowering them to reach their full potential.
                </p>

            </div>
            <div className="about__pastcourse-container">
                <h2 className="about__subtitle">Past courses</h2>
                {pastCourses.map(course=>{
                    return(
                        <div className="about__pastcourse" key={course.name}>
                            <p onClick={(click)=>{navigate(`/courses/${course.name}`)}}className="about__pastcourse__name">{course.landing.date}:</p>
                           <p className="about__pastcourse__text">{course.coursetitle}</p>
                            <p className="about__pastcourse__city">{course.landing.title}</p>

                        </div>

                    )
                })
            }
            </div>

        </section>
    )
}

export default AboutPage;
import Navigation from "../Navigation/Navigation"
import Hamburger from "../Hamburger/Hamburger";

import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";

import "./Header.scss"

function Header(){

    const navigate = useNavigate();
    const onClickLogo = (click) =>{
        navigate("/")
    }





// check if window width to see if hamburger menu should be returned.
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleResize (){ 
        setWindowWidth(window.innerWidth);
    }

    useEffect (()=>{
        window.addEventListener("resize", handleResize);

    },[])

    if (windowWidth < 768) {
        return (
            <header className="header">
                <div onClick={onClickLogo} className="header__logo-container">
                    <p className="header__logo">Dirigierakademie</p>
                </div>
                <div className="header__nav-container">
                    <Hamburger />
                </div>
            </header>
        )

    }else{
        return (
            <header className="header">
                <div onClick={onClickLogo} className="header__logo-container">
                    <p className="header__logo">Dirigierakademie</p>
                </div>
                <div className="header__nav-container">
                    <Navigation/>
                </div>
            </header>
        )
    }


}

export default Header;
import { v4 as uuidv4 } from "uuid";

import {privacy} from "../../assets/privacy";

import "./PrivacyPolicy.scss"
import { useEffect } from "react";

function PrivacyPolicy () {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    
    return(
        <section className="privacy">
            <p className="privacy__title">{privacy.title}</p>
            <p className="privacy__text"> {privacy.summary}</p>
            <p className="privacy__text">Topics:</p>
            <ul>
                {privacy.Topics.map(topic=>{
                    return(
                        <li className="privacy__text" key={uuidv4()}>{topic}</li>
                    )
                })}
            </ul>
            {privacy.questions.map(question=>{
                return(
                    <div className="privacy__div" key={uuidv4()}>
                        <p className="privacy__topic">{question.question}</p>

                        {question.answer.map(item=>{
                            return(
                                <p key={uuidv4() }className="privacy__text">{item}</p>

                            )
                        })}
                        <ul>
                            {question.list.map(item=>{
                                return(
                                    <li className="privacy__text" key={uuidv4()}>{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}
        </section>
    )
}

export default PrivacyPolicy;
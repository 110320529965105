import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import courses from "../../assets/courses";
import FAQ from "../../components/FAQ/FAQ"

import "./CoursesPage.scss"
function CoursesPage (){

    //find clicked course
    const { courseName} = useParams();
    const newCourse = courses.find(course=> course.name === courseName)

    //apply now button navigates to application page
    //for navigating to privacy policy
        const navigate = useNavigate();
        const onClickApply = (click) =>{
            click.preventDefault();
            //check if course is appliable 
            if(applyOpen === true){
                navigate("/apply")
            }
        }

    // gray out apply button when course isn't open for application
    const [applyOpen, setApplyOpen] = useState(true)

    //to hide orchestra photo if there is none
    const [stylePhoto, setStylePhoto] = useState({})

    //to hide information of past events that we don't want to display
    const [stylePastEvent, setStylePastEvent] = useState({})

    useEffect(()=>{
        //gray out apply button
        const newCourse = courses.find(course=> course.name === courseName)
        if(newCourse.applicationstarted === false){
            setApplyOpen(false)
        }else{
            setApplyOpen(true)
        }

        //hide orchestra photo
        if (newCourse.ensemblephoto === "TBD") {
            setStylePhoto({display:"none"})

        }else{
            setStylePhoto({})
        }

        //hide information of past events that we don't want to display
        if (newCourse.pastcourse === true){
            setStylePastEvent({display:"none"})
        }else{
            setStylePastEvent({})
        }


    },[courseName])


    return(
        <section className="courses">
            <div className="courses__summary-container courses__container">
                <div className="courses__photo-container">
                    <img src={newCourse.coursephoto} alt={newCourse.coursetitle} className="courses__photo"/>
                </div>
                <h1 className="courses__title">{newCourse.coursetitle}</h1>
                <div className="courses__details-container">
                {newCourse.coursedetails.map(detail=>{
                    return(
                        <p className="courses__details" key={uuidv4()}>{detail}</p>
                    )
                })}

                </div>
                {newCourse.coursesummary.map(paragraph=>{
                    return(
                        <p className="courses__summary courses__text" key={uuidv4()}>{paragraph}</p>
                    )
                })}
                    <ul className="courses__summary courses__text courses__points">
                {newCourse.participationperks.map(perk=>{
                    return(
                        <li key={perk}>{perk}</li>
                    )
                })}
                </ul>
            </div>
            <div className="courses__apply-container courses__container" style={stylePastEvent}>
                <p>{newCourse.applicationopen}</p>
                <div className="courses__button-container">
                    <button onClick={onClickApply} type="click" className={applyOpen ? "courses__button" : "courses__button__gray courses__button"}>{newCourse.calltoapply}</button>
                </div>
            </div>
            <div className="courses__repertoire-container courses__container">
                <h2 className="courses__subtitle">{newCourse.repertoiretitle}</h2>
                <div className="courses__repertoire">
                    {newCourse.repertoire.map(list=>{
                        return(
                            <div className="courses__repertoire__list" key={uuidv4()}>
                                <p className="courses__repertoire__composer">{list.composer}</p>
                                {list.piece.map(item=>{
                                    return(
                                        <p className="courses__repertoire__piece" key={uuidv4()}>{item}</p>
                                    )    
                            })}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="courses__orchestra-container courses__container">
                <h2 className="courses__subtitle">{newCourse.ensembletitle}</h2>
                <div className="courses__orchestra">
                    <div className="courses__photo-container" style={stylePhoto}>
                        <img src={newCourse.ensemblephoto} alt={newCourse.ensembletitle} className="courses__photo" />
                    </div>
                </div>
                <div className="courses__orchestra__details">
                    {newCourse.ensembledetails.map(paragraph=>{
                        return(
                            <p className="courses__orchestra__summary courses__text" key={uuidv4()}>{paragraph}</p>
                        )
                    })}
                </div>
            </div>
            <div className="courses__schedule-container courses__container">
                <h2 className="courses__subtitle">{newCourse.scheduletitle}</h2>
                <div className="courses__schedule">
                    {newCourse.schedule.map(day=>{
                        return(
                            <div className="courses__date-container" key={uuidv4()} >
                                <p className="courses__date" >{day.date}</p>
                                    {day.time.map(session=>{      
                                        return(
                                            <div className="courses__time-container" key={uuidv4()}>
                                                <p className="courses__time">{session.time}</p>
                                                <p className="courses__objective">{session.objective}</p>
                                            </div>
                                        )
                                    })}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="courses__location-container courses__container" style={stylePastEvent}>
                <h2 className="courses__subtitle">{newCourse.locationtitle}</h2>
                <div className="courses__location">
                    {newCourse.location.map(day=>{
                        return(
                            <div className="courses__location-details" key={day.date}>
                                <p className="courses__location-date">{day.date}</p>
                                <p className="courses__location-venue">{day.venue}</p>
                            </div>
                        )
                    })}

                </div>
            </div>
            <div className="courses__faq-container courses__container" style={stylePastEvent}>
                <h2 className="courses__subtitle">FAQ</h2>
                <div className="courses__faq">
                    {newCourse.faq.map((set)=>{
                        return(
                            <FAQ set={set} key={uuidv4()}/>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default CoursesPage;
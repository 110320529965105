import { useState } from "react";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";

import "./Hamburger.scss"


function Hamburger(){


//check if hamburger icon is clicked. default is no. 
    const [hamburgerIdle, setHamburgerIdle] = useState(true);
    const toggleHamburger = () =>{
        setHamburgerIdle(!hamburgerIdle)
        setTriangleClicked(false)

    }

    const [coursesHighlighted, setCoursesHighlighted] = useState(false)
   //triangle
    const [triangleClicked, setTriangleClicked] = useState(false)
    const triangleClick = (e) =>{
        e.preventDefault();
        setTriangleClicked(!triangleClicked)
    }

if (hamburgerIdle){
    return (
        <div className="hamburger" onClick={toggleHamburger}>
            <div className="hamburger__line hamburger__line1" />
            <div className="hamburger__line hamburger__line2" />
        </div>
    )
}else{
    return(
        <div className="hamburger__menu">
            <HamburgerMenu toggleHamburger={toggleHamburger} coursesHighlighted={coursesHighlighted} setCoursesHighlighted={setCoursesHighlighted} triangleClick={triangleClick} triangleClicked={triangleClicked}/>
        </div>

    )
}
}

export default Hamburger;
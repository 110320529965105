import thomsonheadshot from "../assets/photos/neil-thomson-5.jpeg";
import lynchheadshot from "../assets/photos/matthew-lynch-3.png";

const instructors =[{
    name: "Neil Thomson",
    title: [
        {title:"Music Director", description:"Orquestra Filarmônica de Goiás"},{title:"Professor Emeritus", description:"Royal College of Music, London"}
    ],
    headshot:thomsonheadshot,
    bio: [`Neil Thomson was born in London in 1966, studied violin and viola at the Royal Academy of Music (1984-87) and conducting with Norman Del Mar at the Royal College of Music (1987-89). He was a member of the conducting class at Tanglewood Summer School in 1989 where his teachers included Gustav Meier, Seiji Ozawa, Kurt Sanderling and Leonard Bernstein.`, `From 1992 until 2006 he served as Head of Conducting at the Royal College of Music. The youngest-ever incumbent of this post (first held by Sir Adrian Boult in 1919 and later by Norman Del Mar), he was made an Honorary Member of the RCM in 1994 for his services to the institution and has established an enviable reputation as an orchestral trainer.`, `He has been principal conductor and artistic director of the Goiás Philharmonic Orchestra since 2014, leading the orchestra to national prominence with its championing of Brazilian and contemporary repertoire.`, `In the UK he has conducted the London Symphony Orchestra, the London Philharmonic Orchestra, the Philharmonia, the Royal Philharmonic Orchestra, the Royal Liverpool Philharmonic, the Royal Scottish National Orchestra, the Hallé, the BBC Symphony Orchestra, the Ulster Orchestra and the Orchestra of Welsh National Opera.`, `He also enjoys a busy international career working with the Yomiuri Nippon Symphony Orchestra, Tokyo Philharmonic Orchestra, Tokyo Symphony Orchestra, Russian National Orchestra, São Paulo State Symphony Orchestra (Osesp), WDR Rundfunkorchester, Lahti Symphony Orchestra and the Romanian National Orchestra, among others.
    He has performed with many distinguished soloists including Dame Felicity Lott, Sir Thomas Allen, Sir James Galway, Nelson Freire, Jean Louis Steuerman and Antonio Meneses.`]
},
{
    name: "Matthew Lynch",
    title: [],
    headshot:lynchheadshot,
    bio: [`British/German conductor, Matthew Lynch, enjoys a varied career in both Germany and the UK, working with orchestras such as the Saxon Wind Philharmonic, the Chineke! Orchestras, and the Ulster Orchestra. In addition to symphonic repertoire, he is a regular conductor of opera, and in recent seasons has conducted new productions of La Bohème, Rusalka, and Don Giovanni in Dresden, and Treemonisha at London's Grimeborn Festival.`, `In the 2022/23 season he made several high profile debuts, including with the London Symphony Orchestra, the BBC Concert Orchestra, London Philharmonic Orchestra and the Sydney Symphony Orchestra.`, 
    `In the 2023/24 season he will be returning to London’s Southbank Centre to perform Beethoven’s Eroica with the Chineke! Orchestra and making debuts with (among others) the London Mozart Players, Sinfonia Viva, and the French chamber orchestra, Le Balcon.`,
    `Matthew Lynch studied at Oxford University and the Hochschule für Musik Dresden and continued his studies with Neil Thomson in Paris. He has also attended masterclasses with many renowned conductors, including Paavo and Neeme Järvi, Sian Edwards, Jorma Panula, and Jac van Steen. He was a Conducting Fellow at the Aspen Festival with Robert Spano and attended the Tanglewood Conducting Seminar with Stefan Asbury.`],
}

]

export default instructors;
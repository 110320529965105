import { useForm } from "react-hook-form";

import "./ContactPage.scss"

function ContactPage ({setModalMessage, setModalOpen, modalOpen}){

    const { register, handleSubmit, reset} = useForm();

    const onSubmit = ((event) =>{
        event.formId = "b514b5e0-9100-48c2-96db-0449193f588a"; 
        
        const reqOptions = {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(event)
        };
        fetch('https://yu-form.kyles.wtf/submit-form', reqOptions)
        .then((response)=>{
            console.log(response.ok)
            reset();
            setModalOpen(true)
            setModalMessage([
                `Thank you for contacting us!`, 
                `We've received your contact request and will reach out to you at ${event.contactEmail}.`,
                `Feel free to reach us at contact@dirigierakademie.com.`])
        })
        .catch(error => console.error(error));
    })

    return(
            <section className={modalOpen ? "contact contact__modal" : "contact"}>

                <div className="contact__title-container">
                    <h1 className="contact__title">Get in touch</h1>
                    <p className="contact__description">If you have any questions about the course or the application process please don't hesitate to get in touch.</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="contact__form-container">
                    <div className="contact__short-container">
                        <div className="contact__field-container contact__short">
                            <label className="contact__label" htmlFor="contactFirstName"> First Name *</label>
                            <input type="text" className={modalOpen ? "contact__field contact__field__modal" : "contact__field"} {...register("contactFirstName", {required: true})} />
                        </div>
                        <div className="contact__field-container contact__short">
                            <label className="contact__label" htmlFor="contactLastName"> Last Name *</label>
                            <input type="text" className={modalOpen ? "contact__field contact__field__modal" : "contact__field"} {...register("contactLastName", {required: true})} />
                        </div>
                    </div>
                    <div className="contact__field-container">
                        <label className="contact__label" htmlFor="contactOrganization"> Organization Name</label>
                        <input type="text" className={modalOpen ? "contact__field contact__field__modal" : "contact__field"} {...register("contactOrganization", {})} />
                    </div>
                    <div className="contact__field-container">
                        <label className="contact__label" htmlFor="contactEmail"> Email *</label>
                        <input type="email" className={modalOpen ? "contact__field contact__field__modal" : "contact__field"} {...register("contactEmail", {required: true})} />
                    </div>
                    <div className="contact__field-container contact__message-container">
                        <label className="contact__label" htmlFor="contactMessage"> Message *</label>
                        <textarea className={modalOpen ? "contact__field contact__field__modal contact__message" : "contact__field contact__message"}{...register("contactMessage", {required: true})} />

                    </div>

                    <div className="contact__button-container">
                        <button type="submit" className={modalOpen ? "contact__button contact__button__modal" : "contact__button"}>Send</button>
                    </div>
                </form>
            </section>
    )
}

export default ContactPage;
import { NavLink, Link} from "react-router-dom";
import { useState } from "react";

import courses from "../../assets/courses"

import triangle from "../../assets/icons/triangle.png"


import "./Navigation.scss";

function Navigation () {

    //find current courses
    const currentCourses = courses.filter(array=>array.pastcourse === false)


    
    const [triangleClicked, setTriangleClicked] = useState(false)

    const triangleClick = (e) =>{
        e.preventDefault();
        setTriangleClicked(!triangleClicked);
    } 


    const hideCourses = (e) =>{
        e.preventDefault();
        setTriangleClicked(false)
        setCoursesUnderlined(false)
    }

    //highlight "courses" when used

    const [coursesUnderlined, setCoursesUnderlined] = useState(false)

    const highlightCourses = (e) =>{
        e.preventDefault();
        setCoursesUnderlined(true)
    }

    return(
        <nav className="navigation">
            <ul className="navigation__list">
                <li className="navigation__item" onClick={hideCourses}>
                    <NavLink to="/" className={({ isActive, isPending }) =>
    isPending ? "navigation__pending" : isActive ? "navigation__active navigation__navlink" : "navigation__navlink"
  }>Home</NavLink>
                </li>
                <li className="navigation__item" onClick={hideCourses}>
                    <NavLink to="/about" className={({ isActive, isPending }) =>
    isPending ? "navigation__pending" : isActive ? "navigation__active navigation__navlink" : "navigation__navlink"
  }>About</NavLink>
                </li>
                <li className="navigation__item navigation__courses" onClick={triangleClick}>
                    <p className={coursesUnderlined ? "navigation__active navigation__navlink" : "navigation__navlink"}> Courses</p>
                    <img src={triangle} alt="triangle" className={triangleClicked ? "navigation__triangle__up" : "navigation__triangle__down"}/>
                    <div className={triangleClicked ? "navigation__dropdown-container" : "navigation__dropdown-container__hidden"}>
                        <ul className="navigation__dropdown-list">
                            {currentCourses.map(course=>{
                                return(
                                    <li className="navigation__dropdown-item" key={course.name} onClick={highlightCourses}>
                                        <Link to={`/courses/${course.name}` }className="navigation__dropdown-item">{course.name}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </li>
                <li className="navigation__item" onClick={hideCourses}>
                    <NavLink to="/instructors" className={({ isActive, isPending }) =>
    isPending ? "navigation__pending" : isActive ? "navigation__active navigation__navlink" : "navigation__navlink"
  }>Instructors</NavLink>
                </li>
                <li className="navigation__item" onClick={hideCourses}>
                    <NavLink to="/contact" className={({ isActive, isPending }) =>
    isPending ? "navigation__pending" : isActive ? "navigation__active navigation__navlink" : "navigation__navlink"
  }>Contact</NavLink>
                </li>
                <li className="navigation__item" onClick={hideCourses}>
                    <NavLink to="/apply" className={({ isActive, isPending }) =>
    isPending ? "navigation__pending" : isActive ? "navigation__active navigation__navlink navigation__apply__active" : "navigation__navlink navigation__apply"
  }>Apply</NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation;
import { v4 as uuidv4 } from 'uuid';

import instructors from "../../assets/instructors";

import "./InstructorsPage.scss"

function InstructorsPage (){

    return(
            <section className="instructors">
                {instructors.map(instructor=>{
                    return(
                        <article key={instructor.name} className='instructors__container'>
                            <div className='instructors__headshot-container'>
                                <img src={instructor.headshot} alt={instructor.name} className='instructors__headshot'/>
                            </div>
                            <p className='instructors__name'>{instructor.name}</p>
                            <div className='instructors__details'>
                                {instructor.title.map(title=>{
                                    return (
                                    <div key={uuidv4()}className='instructors__title-container'>
                                        <p className='instructors__title'>{title.title}</p>
                                        <p className='instructors__description'>{title.description}</p>
                                    </div>
                                    )
                                })}

                                <div className='instructors__bio-container'>
                                    {instructor.bio.map(paragraph=>{
                                        return (
                                            <p key={uuidv4()} className='instructors__bio'>{paragraph}</p>
                                        )
                                    })}
                                </div>
                            </div>

                        </article>
                    )
                })}

            </section>
    )
}

export default InstructorsPage;
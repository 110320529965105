import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";


import courses from "../../assets/courses";
import Carousel from "../../components/Carousel/Carousel"
import "./LandingPage.scss"


function LandingPage (){

    //find current courses
    const currentCourses = courses.filter(array=>array.pastcourse === false)


  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleResize (){ 
        setWindowWidth(window.innerWidth);
    }

    useEffect (()=>{
        window.addEventListener("resize", handleResize);
    },[])


    const navigate = useNavigate();


    if (windowWidth < 768){
        return(
            <section className="landing__mobile">
                <Carousel/>
            </section>
        )
    }else{
        return(
            <section className="landing__tablet">
                {currentCourses.map(course=>{
                    return(
                        <article onClick={(click)=>{navigate(`/courses/${course.name}`)}} className="landing__tablet__event" key={course.landing.date}>
                            <div className="landing__tablet__photo-container">
                                <img src={course.landing.landingphoto} alt={course.landing.instructor} className="landing__tablet__photo"/>
                            </div>
                            <div className="landing__tablet__text-container">
                                <h1 className="landing__tablet__title">{course.landing.title}</h1>
                                <h2 className="landing__tablet__date">{course.landing.date}</h2>
                                <h3 className="landing__tablet__instructor">{course.landing.instructor}</h3>
                            </div>
                            <div className="landing__tablet__learn-container">
                                <Link to={`/courses/${course.name}` } className="landing__tablet__learn">Learn more</Link>
                            </div>
                        </article>
                    )
                })}
            </section>
        )
    }

}

export default LandingPage;
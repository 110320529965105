import { useNavigate } from "react-router-dom"

import facebook from "../../assets/icons/facebook-social-media-svgrepo-com.svg"
import instagram from "../../assets/icons/instagram-social-media-svgrepo-com.svg"

import "./Footer.scss"
import { useEffect, useState } from "react";

function Footer (){

    //for navigating to privacy policy
    const navigate = useNavigate();
    const onClickPrivacy = (click) =>{
        navigate("/privacy")
    }



    //check if footer needs to be hidden for landing page
    const [footerHidden, setFooterHidden] = useState(false)
    const currentURL = (window.location.pathname).slice(1).split("/")[0]
    const urlArr = ["about", "courses", "instructors", "contact", "apply", "privacy"]


    useEffect(()=>{
        if (urlArr.includes(currentURL)){
            setFooterHidden(false)
        }else{
            setFooterHidden(true)
        }
            // eslint-disable-next-line
    },[currentURL])


    return (
        <footer className={footerHidden ? "footer__hidden footer" : "footer"}>
            <section className="footer__social-container">
                <a href="https://www.facebook.com/search/top?q=dirigierakademie" className="footer__social-link">
                    <img src={facebook} alt="facebook" className="footer__social-icon"/>
                </a>
                <a href="https://www.instagram.com/dirigierakademie/" className="footer__social-link">
                    <img src={instagram} alt="instagram" className="footer__social-icon"/>
                </a>
            </section>
            <section className="footer__copyright-container">
                <p className="footer__text">©2024 by Dirigierakademie. All rights reserved.</p>
                <p className="footer__text footer__vertical-line"> | </p>
                <p className="footer__text footer__privacy" onClick={onClickPrivacy}>Privacy Policy</p>
            </section>
        </footer>
    )
}

export default Footer